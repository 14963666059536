    const showPopupBtn = document.querySelector('.js-show-newsletter-popup');
    const popupOverlay = document.querySelector('.js-popup-overlay');
    const popup = document.querySelector('.js-popup');
    const closePopupBtn = document.querySelector('.js-popup-close');
  
    showPopupBtn.addEventListener('click', function() {
      popupOverlay.classList.add('-visible');
    });
  
    closePopupBtn.addEventListener('click', function() {
      popupOverlay.classList.remove('-visible');
    });
  
    popupOverlay.addEventListener('click', function(e) {
      if (e.target === popupOverlay) {
        popupOverlay.classList.remove('-visible');
      }
    });
 
  